import PresaleContractABI from './PresaleContract.json';
import BNB_ABI from './BNB_ABI.json';

export const decimals = 18;
export const DEFAULT_NET = 56;
export const SUPPORTED_NET = [56, 97, 250, 25];
export const RPC = {
    56: {url: 'https://bsc-dataseed.binance.org:443', name: 'bscscan', explorerUrl: 'https://bscscan.com/address/'},
    97: {url: 'https://data-seed-prebsc-1-s1.binance.org:8545', name: 'test', explorerUrl: 'https://testnet.bscscan.com/address/'},
    25: {url: 'https://evm-cronos.crypto.org', name: 'cronos', explorerUrl: 'https://cronos.crypto.org/explorer/address/'},
    250: {url: 'https://rpc.ftm.tools/', name: 'fantom', explorerUrl: 'https://ftmscan.com/'}
}
const bscNet = [{
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com/'],
}];
const bscTestNet = [{
    chainId: '0x61',
    chainName: 'BSC Testnet',
    nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
    blockExplorerUrls: ['https://testnet.bscscan.com/'],
}];
const cronosNet = [{
    chainId: '0x19',
    chainName: 'Cronos',
    nativeCurrency: {
        name: 'CRO',
        symbol: 'CRO',
        decimals: 18
    },
    rpcUrls: ['https://evm-cronos.crypto.org/'],
    blockExplorerUrls: ['https://cronos.crypto.org/explorer/'],
}];
const ftmNet = [{
    chainId: '0xFA',
    chainName: 'Fantom Opera',
    nativeCurrency: {
        name: 'FTM',
        symbol: 'FTM',
        decimals: 18
    },
    rpcUrls: ['https://rpc.ftm.tools/'],
    blockExplorerUrls: ['https://ftmscan.com/'],
}];
export const NETWORK_INFO = {
    56: bscNet,
    25: cronosNet,
    250: ftmNet,
    97: bscTestNet
};

export const presaleContract = {
    netId: 56,
    network: 'bscscan',
    address: '0x58600B59033Ff6934c9c2d347a806f67abA4Fbcb',
    abi: PresaleContractABI
}

export const CONTRACT_FOR_BNB = {
    address: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
    abi: BNB_ABI
}
